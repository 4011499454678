import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";

const CommentsShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [comment, setComment] = useState({});
	const [comments, setComments] = useState([]);

	const {
		params: { centerID },
	} = match;
	const dispatch = useDispatch();

	const updataComments = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/opinions/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setComments([
					...response.data.data[0].categories[0].posts[0].data.opinions,
				]);
				setComment({
					...response.data.data[0].categories[0].posts[0].data.opinions.filter(
						commentf => commentf.id === centerID
					)[0],
				});

				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة الانجازات جديد",
				breadcrumb: "الانجازات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		updataComments();
	};

	useEffect(() => {
		onStart();
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		// if (
		// 	comments.filter(Comment => Comment.id !== comment?.id).length === 0 &&
		// 	comments.length > 0
		// ) {
		// 	toast.error("الرجاء ادخال تعليق جديد");
		// 	return;
		// }
		const data = {
			title: "أراء العملاء",
			description: "أراء العملاء",

			opinions: [
				...comments.filter(Comment => Comment.id !== comment?.id),
				{ ...comment },
			],
		};
		// console.log([...comments, { ...comment }]);
		// return;
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/209`,
				{
					category_id: 9,
					admin_id: auth.id,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث الخدمة بنجاح!");
				history.push("/Comments/index");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<div style={{ width: "100%" }}>
			{loading && <Loader />}
			<Container>
				<Row style={{ marginTop: "2rem" }}>
					<Col
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<div className="form-item">
							<label htmlFor="clientNameAR">اسم المستخدم</label>

							<input
								type="text"
								id="clientNameAR"
								name="clientNameAr"
								value={comment?.name?.ar ?? ""}
								onChange={e => {
									setComment({
										...comment,
										name: { ...comment.name, ar: e.target.value },
									});
								}}
							/>
						</div>
					</Col>

					<Col
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<div className="form-item">
							<label htmlFor="clientNameEN">اسم المستخدم بالانجليزية</label>

							<input
								type="text"
								id="clientNameEN"
								name="clientNameEn"
								value={comment?.name?.en ?? ""}
								onChange={e => {
									setComment({
										...comment,
										name: { ...comment.name, en: e.target.value },
									});
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row style={{ paddingTop: "1rem" }}>
					<Col
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<div className="form-item">
							<label htmlFor="JobNameAR">الوظيفة</label>

							<input
								type="text"
								id="JobNameAR"
								name="jobNameAR"
								value={comment?.position?.ar ?? ""}
								onChange={e => {
									setComment({
										...comment,
										position: { ...comment.position, ar: e.target.value },
									});
								}}
							/>
						</div>
					</Col>

					<Col
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
					>
						<div className="form-item">
							<label htmlFor="JobNameEN"> الوظيفة بالانجليزية </label>

							<input
								type="text"
								id="JobNameEN"
								name="jobNameEN"
								value={comment?.position?.en ?? ""}
								onChange={e => {
									setComment({
										...comment,
										position: { ...comment.position, en: e.target.value },
									});
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="form-item">
							<label htmlFor="CommentAR">تفصيل التعليق </label>

							<textarea
								name=""
								id="CommentAR"
								rows="5"
								value={comment?.opinion?.ar ?? ""}
								onChange={e => {
									setComment({
										...comment,
										opinion: { ...comment?.opinion, ar: e.target.value },
									});
								}}
							></textarea>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="form-item">
							<label htmlFor="CommentEN">تفصيل التعليق بالانجليزية</label>

							<textarea
								name=""
								id="CommentEN"
								rows="5"
								value={comment?.opinion?.en ?? ""}
								onChange={e => {
									setComment({
										...comment,
										opinion: { ...comment?.opinion, en: e.target.value },
									});
								}}
							></textarea>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className="form-item mt-3">
							<button
								className="bt bt-success btn-block"
								onClick={handleSubmit}
							>
								ارسال
							</button>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CommentsShow;
