import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import Loader from "../../components/shared/Loader";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { useDropzone } from "react-dropzone";
import { Col, Container, Row } from "reactstrap";

const ServicesCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [currentType, setCurrentType] = useState({});
	const [title, setTitle] = useState({ ar: "", en: "" });
	const [description, setDescription] = useState({ ar: "", en: "" });
	const [pageContent, setPageContent] = useState({ ar: "", en: "" });
	const [picture, setPicture] = useState(userPlaceholder);
	const [featureTitle, setFeatureTitle] = useState("");
	const [features, setFeatures] = useState([]);
	const [files, setFiles] = useState([]);
	const typesForm = useRef(null);
	// const [catalog, setCatalog] = useState([]);
	// const [selectedCatalog, setSelectedCatalog] = useState([]);
	const [imageArray, setImageArray] = useState([]);
	const [types, setTypes] = useState([]);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	useEffect(
		() => () => {
			files.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة خدمة جديدة",
				breadcrumb: "الخدمات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const pushToInformation = e => {
		e.preventDefault();
		if (featureTitle === "") {
			toast.error("برجاء تعبئة الحقول المطلوبة!");
			return;
		}
		const data = { title: featureTitle };
		features.push(data);
		setFeatures(features);
		setFeatureTitle("");
	};

	const handleDeleteFeature = (event, information) => {
		event.preventDefault();
		const arr = features.filter(function (item) {
			return item.title !== information.title;
		});
		setFeatures(arr);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	// const uploadCatalog = useDropzone({
	// 	accept: ".jpg, .png, .pdf, .doc, .docx, .ppt, .pptx",
	// 	multiple: false,
	// 	onDrop: acceptedFiles => {
	// 		setCatalog(
	// 			acceptedFiles.map(file =>
	// 				Object.assign(file, {
	// 					preview: URL.createObjectURL(file),
	// 				})
	// 			)
	// 		);
	// 	},
	// });
	const handleTypePicture = e => {
		setLoading(true);
		const data = new FormData();
		data.append("file", e.target.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setCurrentType({ ...currentType, icon: response.data.data.name });
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	// const catalogPreview = catalog.map(catalog => (
	// 	<div className="catalog my-3">
	// 		<i className="fas fa-file-alt" /> {catalog?.name}
	// 	</div>
	// ));
	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.name);
					setImageArray(imageArray);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	// const handleCatalogUpload = async e => {
	// 	if (!catalog.length) {
	// 		toast.error("برجاء إختيار ملف اولا");
	// 		return;
	// 	}
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	for (let i = 0; i < catalog.length; i++) {
	// 		const formData = new FormData();
	// 		formData.append(`file`, catalog[i]);
	// 		await axios
	// 			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
	// 				headers: {
	// 					Authorization: `Bearer ${cookie.get("token")}`,
	// 					Accept: "application/json",
	// 					"Content-Type": "multipart/form-data",
	// 				},
	// 			})
	// 			.then(function (responseImage) {
	// 				selectedCatalog.push(responseImage.data.data.name);
	// 				setSelectedCatalog(selectedCatalog);
	// 			})
	// 			.catch(function (error) {
	// 				toast.error("خطأ، برجاء المحاولة مره اخرى!");
	// 				setLoading(false);
	// 			});
	// 	}
	// 	setLoading(false);
	// 	toast.success("تم رفع الملف بنجاح!");
	// };

	const handleSubmit = e => {
		e.preventDefault();
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const data = {
						// data Form
						title: title,
						description: description,
						icon: response.data.data.name,
						page_content: pageContent,
						// features: features,
						types: types ?? [],
						images: imageArray,
					};
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/posts`,
							{
								category_id: 3,
								admin_id: auth.id,
								data: data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/services/index");
							toast.success("تم اضافة الخدمة بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="titleAR">العنوان</label>
									<input
										type="text"
										id="titleAR"
										required
										value={title.ar}
										onChange={e => setTitle({ ...title, ar: e.target.value })}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="titleEn">العنوان</label>
									<input
										type="text"
										id="titleEn"
										required
										value={title.en}
										onChange={e => setTitle({ ...title, en: e.target.value })}
									/>
								</div>

								{/* Description Section Starts HERE */}

								<div className="form-item">
									<label htmlFor="descriptionAR">الوصف</label>
									<CKEditor
										id="descriptionAR"
										data={description.ar ?? ""}
										required
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											console.log(description);
											setDescription({ ...description, ar: data });
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
											extraPlugins: [MyCustomUploadAdapterPlugin],
										}}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="descriptionEn">الوصف بالانجليزية</label>
									<CKEditor
										id="descriptionEn"
										data={description.en ?? ""}
										required
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescription({ ...description, en: data });
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "ltr",
											language: "en",
											extraPlugins: [MyCustomUploadAdapterPlugin],
										}}
									/>
								</div>

								{/* Description Section Ends HERE */}

								{/* Contant Section */}
								<div className="form-item">
									<label htmlFor="pageContentAr"> المحتواي </label>
									<CKEditor
										data={pageContent.ar}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setPageContent({ ...pageContent, ar: data });
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
											extraPlugins: [MyCustomUploadAdapterPlugin],
										}}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="pageContentEn"> المحتواي بالانجليزية</label>
									<CKEditor
										data={pageContent.en}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setPageContent({ ...pageContent, en: data });
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "ltr",
											language: "en",
											extraPlugins: [MyCustomUploadAdapterPlugin],
										}}
									/>
								</div>

								{/*  */}

								<div className="dropzoneContainer">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>اضغط هنا لرفع الصورة الداخلية</p>
									</div>
									<aside className="thumbsContainer">{thumbs}</aside>
									<button
										onClick={handleImageUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الصورة
									</button>
								</div>
								<div className="row">
									{imageArray.map(img => (
										<div className="col-md-2" key={img}>
											<div className="image-preview">
												<img
													src={`${process.env.REACT_APP_MAIN_URL}${img}`}
													alt={img}
													className="imgList"
												/>
												<button onClick={e => deleteImage(e, img)}>
													<i className="fas fa-trash"></i>
												</button>
											</div>
										</div>
									))}
								</div>

								{/* <div className="dropzoneContainer mt-4">
									<div
										{...uploadCatalog.getRootProps({ className: "dropzone" })}
									>
										<input {...uploadCatalog.getInputProps()} />
										<p>اضغط هنا لرفع الكتالوج</p>
									</div>
									<aside className="thumbsContainer">{catalogPreview}</aside>
									<button
										onClick={handleCatalogUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الملف
									</button>
								</div> */}
								{/* <div className="row">
									{selectedCatalog.map(catalog => (
										<div className="col-md-3" key={catalog}>
											<div className="image-preview">
												<div className="catalog my-3">
													<i className="fas fa-file-alt" /> {catalog.split("/").pop()}
												</div>
												<button onClick={e => deleteCatalog(e, catalog)}>
													<i className="fas fa-trash"/>
												</button>
											</div>
										</div>
									))}
								</div> */}
								{/* <br />
								<hr />
								<div className="form-item">
									<label htmlFor="question">عنوان الميزة</label>
									<input
										type="text"
										id="question"
										value={featureTitle.ar}
										onChange={e =>
											setFeatureTitle(v => {
												return { ...v, ar: e.target.value };
											})
										}
									/>
								</div>
								<div className="form-item" />
								<button className="bt bt-success" onClick={pushToInformation}>
									اضافة الميزة
								</button>
								<div className="form-item" style={{ marginTop: "40px" }}>
									<ul className="list-unstyled pointList">
										{features.map(feature => (
											<li key={feature.title}>
												<button onClick={e => handleDeleteFeature(e, feature)}>
													<i className="fas fa-trash" />
												</button>
												<span>{feature.title}</span>
											</li>
										))}
									</ul>
								</div> */}
							</div>
							<hr />
							<div className="form-item" />
							<form ref={typesForm}>
								<div className="form-item">
									<Container
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "2rem",
										}}
									>
										<Row>
											<Col>
												<div
													className=""
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													<label htmlFor="TypesNameAR">اسم الاضافة</label>
													<input
														type="text"
														id="TypesNameAR"
														placeholder="ادخل اسم الاضافة"
														onChange={e =>
															setCurrentType({
																...currentType,
																title: {
																	...currentType.title,
																	ar: e.target.value,
																},
															})
														}
													/>
												</div>
												<div
													className=""
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													<label htmlFor="TypesNameAR">
														اسم الاضافة بالانجليزية
													</label>
													<input
														type="text"
														id="TypesNameAR"
														placeholder="ادخل اسم الاضافة بالانجليزية"
														onChange={e =>
															setCurrentType({
																...currentType,
																title: {
																	...currentType.title,
																	en: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className=""
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													<label htmlFor="typeDescriptionAr">
														{" "}
														وصف الاضافة
													</label>
													<input
														type="text"
														id="typeDescriptionAr"
														placeholder="ادخل اسم وصف الاضافة "
														onChange={e =>
															setCurrentType({
																...currentType,
																description: {
																	...currentType.description,
																	ar: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
											<Col>
												<div
													className=""
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													<label htmlFor="typeDescriptionEn">
														{" "}
														وصف الاضافة بالانجليزية
													</label>
													<input
														type="text"
														id="typeDescriptionEn"
														placeholder="ادخل اسم وصف الاضافة بالانجليزية"
														onChange={e =>
															setCurrentType({
																...currentType,
																description: {
																	...currentType.description,
																	en: e.target.value,
																},
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row style={{ alignItems: "flex-end" }}>
											<Col>
												<label htmlFor="typeIcon" style={{ cursor: "pointer" }}>
													الصورة
													<i
														style={{
															color: "rgb(33 82 191)",
															fontWeight: "bolder",
															fontSize: "14px",
															marginRight: "1rem",
														}}
													>
														<i class="fas fa-upload"></i>
													</i>
												</label>
												<input
													type="file"
													id="typeIcon"
													hidden
													accept="image/*"
													onChange={handleTypePicture}
												/>
												<p>
													{currentType.icon
														? ` تم رفع الصوره بنجاح${currentType.icon}`
														: "الرجاء اختيار صورة للاضافة"}
												</p>
											</Col>
											<Col xs={12} md={3}>
												<div
													className=""
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "flex-start",
														flexDirection: "column",
													}}
												>
													<label htmlFor="typeColor">
														{" "}
														اختار اللون المناسب
													</label>
													<input
														type="color"
														id="typeColor"
														name={"color"}
														onChange={e =>
															setCurrentType({
																...currentType,
																[`${e.target.name}`]: e.target.value,
															})
														}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col
												xs={12}
												style={{
													justifyContent: "center",
													alignItems: "center",
													display: "flex",
												}}
											>
												<button
													className={"bt bt-success btn-block"}
													onClick={e => {
														e.preventDefault();
														if (
															!currentType.color ||
															!currentType.description ||
															!currentType.title
														) {
															toast.warn("الرجاء ادخال البيانات اولا");
															return;
														}
														if (!currentType.icon) {
															toast.warn("الرجاء اختيار الصورة اولا");
															return;
														}
														types.filter(type => type === currentType)
															.length === 0
															? setTypes([...types, currentType])
															: toast.error("الرجاء اضافة اضافه جديدة");
														setCurrentType({});
														typesForm.current.reset();
													}}
												>
													اضافة الاضافة
												</button>
											</Col>
										</Row>
										<Row>
											{types.length > 0
												? types.map((type, index) => {
														return (
															<Col xs={12} md={4} lg={3} key={`${index}Types`}>
																<div
																	className=""
																	style={{
																		backgroundImage: `url(${process.env.REACT_APP_MAIN_URL}${type.icon})
															`,
																		width: "100%",
																		height: "100px",
																		objectFit: "cover",
																		backgroundSize: "100%",
																		backgroundRepeat: "no-repeat",
																		position: "relative",
																	}}
																>
																	<span
																		style={{
																			backgroundColor: `${type.color}`,
																			width: "20%",
																			height: "20%",
																			position: "absolute",
																			top: "70%",
																			left: "5%",
																			transform: "translate(-5%, -70%)",
																		}}
																	></span>
																	<span
																		onClick={e => {
																			e.preventDefault();
																			setTypes(v => [
																				...v.filter(
																					deltype => type !== deltype
																				),
																			]);
																		}}
																		style={{
																			width: "fit-content",
																			height: "10px",
																			position: "absolute",
																			top: "5%",
																			left: "90%",
																			transform: "translate(-90%, -5%)",
																			cursor: "pointer",
																			fontWeight: "bolder",
																			color: "red",
																		}}
																	>
																		<i className="fas fa-trash" />
																	</span>
																</div>
																<p>{type?.title?.ar ?? ""}</p>
																<p>{type?.description?.ar ?? ""}</p>
															</Col>
														);
												  })
												: ""}
										</Row>
									</Container>
								</div>
							</form>
							<br />
							<hr />
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = loader => {
		return new MyUploadAdapter(loader);
	};
}

class MyUploadAdapter {
	constructor(props) {
		this.loader = props;
		this.url = `${process.env.REACT_APP_API_URL}/upload`;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = (this.xhr = new XMLHttpRequest());

		xhr.open("POST", this.url, true);
		xhr.responseType = "json";
		xhr.setRequestHeader("Authorization", `Bearer ${cookie.get("token")}`);
	}

	_initListeners(resolve, reject) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

		xhr.addEventListener("error", () => reject(genericErrorText));
		xhr.addEventListener("abort", () => reject());
		xhr.addEventListener("load", () => {
			const response = xhr.response;
			if (!response || response.error) {
				return reject(
					response && response.error ? response.error.message : genericErrorText
				);
			}
			resolve({
				default: response.data.filePath,
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener("progress", evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	_sendRequest() {
		const data = new FormData();
		this.loader.file.then(result => {
			data.append("file", result);
			this.xhr.send(data);
		});
	}
}

export default ServicesCreate;
