import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { useDropzone } from "react-dropzone";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";
import { FormGroup, Col, Row, Container } from "reactstrap";

const WorksCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [Group, setGroup] = useState(0);
	const [formDATA, setFormDATA] = useState({});
	const [files, setFiles] = useState([]);
	const [vedio, setVedio] = useState();
	const [imageArray, setImageArray] = useState([]);
	const [picture, setPicture] = useState(userPlaceholder);
	const videoRef = useRef(null);

	// const [title, setTitle] = useState("");
	// const [type, setType] = useState("");
	// const [titleEn, setTitleEn] = useState("");
	// const [typeEn, setTypeEn] = useState("");
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const onStart = () => {
		setLoading(false);
		dispatch(
			updateMeta({
				title: "اضافة عمل جديد",
				breadcrumb: "الأعمال",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					console.log("imges", responseImage);
					setImageArray([...imageArray, responseImage.data.data.name]);
				})
				.catch(function (error) {
					console.log(error);
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const handleVideo = e => {
		setLoading(true);
		const fileInput = e.target;
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setVedio(response.data.data.name);
				console.log(response);

				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				console.log(error);
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const data = {
						...formDATA,
						GroupType: Group,
						main_image: response.data.data.name,
						video: vedio,
						images: imageArray,
						descriptionInBrief: {
							ar: new DOMParser().parseFromString(
								formDATA?.description?.ar,
								"text/html"
							).body.innerText,
							en: new DOMParser().parseFromString(
								formDATA?.description?.en,
								"text/html"
							).body.innerText,
						},
					};
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/posts`,
							{
								category_id: 31,
								admin_id: auth.id,
								data: data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/works/index");
							toast.success("تم اضافة العمل بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												id="title"
												required
												value={formDATA?.title?.ar ?? ""}
												onChange={e =>
													setFormDATA({
														...formDATA,
														title: {
															...formDATA?.title,
															ar: e.target.value,
														},
													})
												}
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزيه</label>
											<input
												type="text"
												id="title"
												required
												value={formDATA?.title?.en ?? ""}
												onChange={e =>
													setFormDATA({
														...formDATA,
														title: {
															...formDATA?.title,
															en: e.target.value,
														},
													})
												}
											/>
										</div>
									</div>

									{/* Description Section */}
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">الوصف</label>
											<CKEditor
												data={formDATA?.description?.ar ?? ""}
												onReady={editor => {
													editor.ui
														.getEditableElement()
														.parentElement.insertBefore(
															editor.ui.view.toolbar.element,
															editor.ui.getEditableElement()
														);
													editor = editor;
												}}
												onError={({ willEditorRestart }) => {
													if (willEditorRestart) {
														editor.ui.view.toolbar.element.remove();
													}
												}}
												onChange={(event, editor) => {
													const data = editor.getData();
													setFormDATA({
														...formDATA,
														description: {
															...formDATA?.description,
															ar: data,
														},
													});
												}}
												editor={DecoupledEditor}
												config={{
													contentsLangDirection: "rtl",
													language: "ar",
													extraPlugins: [MyCustomUploadAdapterPlugin],
												}}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="DescriptionEN">الوصف بالانجليزية</label>
											<CKEditor
												data={formDATA?.description?.en ?? ""}
												onReady={editor => {
													editor.ui
														.getEditableElement()
														.parentElement.insertBefore(
															editor.ui.view.toolbar.element,
															editor.ui.getEditableElement()
														);
													editor = editor;
												}}
												onError={({ willEditorRestart }) => {
													if (willEditorRestart) {
														editor.ui.view.toolbar.element.remove();
													}
												}}
												onChange={(event, editor) => {
													console.log(editor);
													const data = editor.getData();
													setFormDATA({
														...formDATA,
														description: {
															...formDATA?.description,
															en: data,
														},
													});
												}}
												editor={DecoupledEditor}
												config={{
													contentsLangDirection: "ltr",
													language: "en",
													extraPlugins: [MyCustomUploadAdapterPlugin],
												}}
											/>
										</div>
									</div>
									{/* Description ends Here */}

									{/* ProjectInfo Section */}
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="ClientAr">اسم العميل </label>
												<input
													type="text"
													id="ClientAr"
													required
													value={formDATA?.customer?.ar ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															customer: {
																...formDATA?.customer,
																ar: e.target.value,
															},
														})
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="ClientEn">اسم العميل بالانجليزية</label>
												<input
													type="text"
													id="ClientEn"
													required
													value={formDATA?.customer?.en ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															customer: {
																...formDATA?.customer,
																en: e.target.value,
															},
														})
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="CountryAr">اسم البلد </label>
												<input
													type="text"
													id="CountryAr"
													required
													value={formDATA?.country?.ar ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															country: {
																...formDATA?.country,
																ar: e.target.value,
															},
														})
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="CountryEn">اسم البلد بالانجليزية</label>
												<input
													type="text"
													id="CountryEn"
													required
													value={formDATA?.country?.en ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															country: {
																...formDATA?.country,
																en: e.target.value,
															},
														})
													}
												/>
											</div>
										</div>
									</FormGroup>
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="categoryAr">اسم القسم </label>
												<input
													type="text"
													id="categoryAr"
													required
													value={formDATA?.category?.ar ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															category: {
																...formDATA?.category,
																ar: e.target.value,
															},
														})
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="categoryEn">
													اسم القسم بالانجليزية
												</label>
												<input
													type="text"
													id="categoryEn"
													required
													value={formDATA?.category?.en ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															category: {
																...formDATA?.category,
																en: e.target.value,
															},
														})
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="ExpirdataAr">تاريخ الانتهاء</label>
												<input
													type="date"
													id="ExpirdataAr"
													required
													value={formDATA?.expireDate ?? ""}
													onKeyPress={e =>
														setFormDATA({
															...formDATA,
															expireDate: e.target.value,
														})
													}
													onChange={e =>
														setFormDATA({
															...formDATA,
															expireDate: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</FormGroup>
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="budgetAr">الميزانية</label>
												<input
													type="text"
													id="budgetAr"
													required
													value={formDATA?.budget?.ar ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															budget: {
																...formDATA?.budget,
																ar: e.target.value,
															},
														})
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="budgetEn"> الميزانية بالانجليزية</label>
												<input
													type="text"
													id="budgetEn"
													required
													value={formDATA?.budget?.en ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															budget: {
																...formDATA?.budget,
																en: e.target.value,
															},
														})
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="customerwebsiteAr">اسم موقع </label>
												<input
													type="text"
													id="customerwebsiteAr"
													required
													value={formDATA?.customer_website ?? ""}
													onChange={e =>
														setFormDATA({
															...formDATA,
															customer_website: e.target.value,
														})
													}
												/>
											</div>
										</div>
									</FormGroup>
									{/* ProjectInfo ends Here */}
									{/* Images Zone*/}

									<div className="dropzoneContainer">
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<p>اضغط هنا لرفع الصورة الداخلية</p>
										</div>
										<aside className="thumbsContainer">{thumbs}</aside>
										<button
											onClick={handleImageUpload}
											type="button"
											className="bt bt-success"
										>
											رفع الصورة
										</button>
									</div>

									<div className="row">
										{imageArray.map(img => (
											<div className="col-md-2" key={img}>
												<div className="image-preview">
													<img
														src={`${process.env.REACT_APP_MAIN_URL}${img}`}
														alt={img}
														className="imgList"
													/>
													<button onClick={e => deleteImage(e, img)}>
														<i className="fas fa-trash"></i>
													</button>
												</div>
											</div>
										))}
									</div>
									{/* Images Zone Ends Here */}
									{/* Video Zone  */}
									<div className="row">
										<Col xs={12}>
											<label htmlFor="Video" style={{ position: "relative" }}>
												الفديو
												<span
													style={{
														position: "absolute",
														top: "50%",
														left: "-50%",
														fontSize: "20px",
														cursor: "pointer",
														fontWeight: "bolder",
														transform: "translateY(-50%)",
													}}
												>
													<i>+</i>
												</span>
											</label>
											<input
												type="file"
												id="Video"
												hidden
												accept="video/mp4,video/x-m4v,video/*"
												onChange={handleVideo}
											/>
											<div className="" style={{ position: "relative" }}>
												<video
													controls
													ref={videoRef}
													style={{ width: "100%" }}
												>
													<source
														src={
															vedio
																? process.env.REACT_APP_MAIN_URL + vedio
																: process.env.REACT_APP_MAIN_URL +
																  "/storage/uploads/ZC9TaJ-1636382296.mp4"
														}
														type="video/mp4"
													></source>
												</video>
											</div>
										</Col>
									</div>
									{/* Video Zone Ends Here */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<div className="card">
								<div className="card-body">
									<label htmlFor="GroupSelector">النوع</label>
									<select
										name=""
										id="GroupSelector"
										onChange={e => setGroup(e.target.value)}
									>
										<option value="2">المقاولات</option>
										<option value="1">هيئات حكومية</option>
										<option value="0">الاستشارات الهندسية</option>
									</select>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = loader => {
		return new MyUploadAdapter(loader);
	};
}

class MyUploadAdapter {
	constructor(props) {
		this.loader = props;
		this.url = `${process.env.REACT_APP_API_URL}/upload`;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = (this.xhr = new XMLHttpRequest());

		xhr.open("POST", this.url, true);
		xhr.responseType = "json";
		xhr.setRequestHeader("Authorization", `Bearer ${cookie.get("token")}`);
	}

	_initListeners(resolve, reject) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

		xhr.addEventListener("error", () => reject(genericErrorText));
		xhr.addEventListener("abort", () => reject());
		xhr.addEventListener("load", () => {
			const response = xhr.response;
			if (!response || response.error) {
				return reject(
					response && response.error ? response.error.message : genericErrorText
				);
			}
			resolve({
				default: response.data.filePath,
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener("progress", evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	_sendRequest() {
		const data = new FormData();
		this.loader.file.then(result => {
			data.append("file", result);
			this.xhr.send(data);
		});
	}
}

export default WorksCreate;
