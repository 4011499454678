import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const Achivmentindex = () => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الانجازات",
				breadcrumb: "الانجازات",
				button: {
					show: true,
					text: "اضافة خدمة جديدة",
					link: "/achivment/create",
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/Statistic_page/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log(response.data.data[0].categories[3].posts);
				setData([...response.data.data[0].categories[3].posts]);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};
	const handleDelete = postID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/posts/${postID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};
	return (
		<section>
			{loading && <Loader />}
			<section>
				<div className="row">
					{data.map(post => (
						<div className="col-md-3" key={post.id}>
							<div className="serviceItem">
								<button onClick={() => handleDelete(post.id)}>
									<i className="fas fa-trash"></i>
								</button>
								<Link to={`/achivment/show/${post.id}`}>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}${post.data.image}`}
										alt={post.data.title.ar}
									/>
									<h1>{post.data?.title?.ar ?? ""}</h1>
									<p>{post.data?.description?.ar ?? ""}</p>
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>
		</section>
	);
};

export default Achivmentindex;
/*
{data.map(post => (
								<div className="col-md-3" key={post.id}>
									<div className="serviceItem">
										<button onClick={() => handleDelete(post.id)}>
											<i className="fas fa-trash"></i>
										</button>
										<Link to={`/services/show/${post.id}`}>
											<img
												src={`${process.env.REACT_APP_MAIN_URL}${post.data.icon}`}
												alt={post.data.title.ar}
											/>
											<h1>{post.data?.title?.ar ?? ""}</h1>
											<p>{post.data?.description?.ar ?? ""}</p>
										</Link>
									</div>
								</div>
							))
						)
					)}
*/
