import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import Select from "react-select";
import Loader from "../../components/shared/Loader";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";
import { v4 } from "uuid";
const options = [
	{ value: "chocolate", label: "Chocolate" },
	{ value: "strawberry", label: "Strawberry" },
	{ value: "vanilla", label: "Vanilla" },
];

const BlogShow = ({ match }) => {
	const {
		params: { blogID },
	} = match;
	const parser = new DOMParser();
	const auth = useSelector(state => state.auth.user);
	const [titleAr, setTitleAr] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [descriptionAr, setDescriptionAr] = useState("");
	const [descriptionEN, setDescriptionEn] = useState("");
	const [contentAr, setContentAr] = useState("");
	const [contentEn, setContentEn] = useState("");
	const [picture, setPicture] = useState(userPlaceholder);
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [quote, setQoute] = useState({});
	const [selectedCategory, setSelectedCategory] = useState(0);
	const dispatch = useDispatch();
	const [tagsOptions, setTagsOptions] = useState([]);
	const [selectTages, setSelectTags] = useState([]);

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const handleTagsChange = options => {
		setSelectTags([...options.map(option => option.value)]);
	};
	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل المقال",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blogs/${blogID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setTitleAr(response.data.data.title.ar);
				setTitleEn(response.data.data.title.en);
				setDescriptionAr(response.data.data.description.ar);
				setDescriptionEn(response.data.data.description.ar);
				setContentEn(
					response.data?.data?.contant?.en ? response.data.data.contant.en : ""
				);
				setContentAr(
					response.data?.data?.contant?.ar ? response.data.data.contant.ar : ""
				);
				setQoute(
					response.data.data.quots
						? response.data.data.quots
						: { ar: "", en: "" }
				);
				setPicture(response.data.data.image);
				setSelectedCategory(response.data.data.category_id);
				setSelectTags([...response.data.data.tags.map(option => option.id)]);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
		axios
			.get(`${process.env.REACT_APP_API_URL}/blog_categories`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data);
				axios
					.get(`${process.env.REACT_APP_API_URL}/tags`, {
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					})
					.then(response => {
						setTagsOptions([
							...response.data.data.map(option => ({
								value: option.id,
								label: option.name,
							})),
						]);
						setLoading(false);
					})
					.catch(function (error) {
						toast.error("خطأ، برجاء المحاولة لاحقا!");
					});
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		console.log({
			title: {
				ar: titleAr,
				en: titleEn,
			},
			description: {
				ar: descriptionAr,
				en: descriptionEN,
			},
			descriptionInBrev: {
				ar:
					parser.parseFromString(descriptionAr, "text/html").body.children[0]
						.innerText + "...",
				en:
					parser.parseFromString(descriptionEN, "text/html").body.children[0]
						.innerText + "...",
			},
			contant: {
				ar: contentAr,
				en: contentEn,
			},
			tags: selectTages,
			quots: quote,
			admin_id: auth.id,
			image: picture,
			category_id: selectedCategory,
		});
		// return;
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/blogs/${blogID}`,
				{
					category_id: selectedCategory,
					title: {
						ar: titleAr,
						en: titleEn,
					},
					description: {
						ar: descriptionAr,
						en: descriptionEN,
					},
					descriptionInBrev: {
						ar:
							parser.parseFromString(descriptionAr, "text/html").body
								.children[0].innerText + "...",
						en:
							parser.parseFromString(descriptionEN, "text/html").body
								.children[0].innerText + "...",
					},
					contant: {
						ar: contentAr,
						en: contentEn,
					},
					tags: selectTages,
					quots: quote,
					admin_id: auth.id,
					image: picture,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث المقالة بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};
	// onSubmit={handleSubmit}

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-9">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="category">التصنيف</label>
								<select
									id="category"
									value={selectedCategory}
									onChange={e => setSelectedCategory(e.target.value)}
								>
									{categories.map(category => (
										<option value={category.id} key={category.id}>
											{category.name.ar}
										</option>
									))}
								</select>
							</div>
							<div className="form-item">
								<label htmlFor="title">العنوان</label>
								<input
									type="text"
									id="title"
									required
									onChange={e => setTitleAr(e.target.value)}
									value={titleAr}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="title">العنوان باللغة الإنجليزية</label>
								<input
									type="text"
									id="title"
									required
									onChange={e => setTitleEn(e.target.value)}
									value={titleEn}
								/>
							</div>

							<div className="form-item">
								<label htmlFor="title">اقتبس</label>
								<input
									type="text"
									id="title"
									required
									onChange={e =>
										setQoute(v => {
											return { ...v, ar: e.target.value };
										})
									}
									value={quote?.ar ? quote.ar : ""}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="title">اقتبس باللغة الإنجليزية</label>
								<input
									type="text"
									id="title"
									required
									onChange={e =>
										setQoute(v => {
											return { ...v, en: e.target.value };
										})
									}
									value={quote?.en ? quote.en : ""}
								/>
							</div>

							<div className="form-item">
								<label htmlFor="description">الوصف</label>
								<CKEditor
									data={descriptionAr}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescriptionAr(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="description">الوصف باللغة الإنجليزية</label>
								<CKEditor
									data={descriptionEN}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescriptionEn(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>

							{/* Content */}

							<div className="form-item">
								<label htmlFor="description">المحتوي</label>
								<CKEditor
									data={contentAr}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContentAr(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="description">المحتوي باللغة الإنجليزية</label>
								<CKEditor
									data={contentEn}
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContentEn(data);
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>
							<div className="form-item">
								<div style={{ marginBottom: "8rem" }}>
									<Select
										value={[
											...tagsOptions.map(options=> selectTages.find( elem => elem===options.value) ? options :"" )
										]}
										onChange={handleTagsChange}
										isLoading={loading}
										isMulti={true}
										options={tagsOptions}
									></Select>
								</div>
							</div>

							{/* Content END */}
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<input type="file" onChange={handlePicture} id="picture" />
								<img
									src={`${process.env.REACT_APP_MAIN_URL}${picture}`}
									alt="avatar"
									className="imagePreview"
								/>
							</div>
						</div>
					</div>

					<div className="form-item mt-3">
						<button className="bt bt-success btn-block" onClick={handleSubmit}>
							ارسال
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogShow;
