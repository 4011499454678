import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Container, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import Loader from "../../components/shared/Loader";
const AchivmentShow = ({ match }) => {
	const [loading, setLoading] = useState(false);
	const {
		params: { centerID },
	} = match;
	const history = useHistory();

	const auth = useSelector(state => state.auth.user);
	const [picture, setPicture] = useState(userPlaceholder);
	const [AchivmentData, setAchivmentData] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل الخدمة",
				breadcrumb: "الخدمات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${centerID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setLoading(false);
				setPicture(response.data.data.data.image ?? "");
				setAchivmentData(response.data.data.data ?? {});
				setDataLoaded(true);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};
	useEffect(() => {
		onStart();
	}, []);

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				console.log(error);
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const HandleSubmit = e => {
		e.preventDefault();
		if (
			!AchivmentData?.title?.ar ||
			!AchivmentData?.title?.en ||
			!AchivmentData?.value ||
			picture.length <= 0
		)
			return;
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${centerID}`,
				{
					category_id: 46,
					admin_id: auth.id,
					data: { ...AchivmentData, image: picture },
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/achivment/index");
				toast.success("تم اضافة الانجاز بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
                console.log(error);
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div style={{ width: "100%" }}>
				<Container>
					<Row>
						<Col>
							<Container
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									flexDirection: "column",
									gap: "2rem",
								}}
							>
								<Row style={{ width: "100%" }}>
									<Col>
										<input
											type="text"
											name=""
											value={AchivmentData?.title?.ar ?? ""}
											id="AchivmentNameAR"
											placeholder="ادخل اسم الانجاز"
											onChange={e =>
												setAchivmentData({
													...AchivmentData,
													title: { ...AchivmentData.title, ar: e.target.value },
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<input
											type="text"
											name=""
											value={AchivmentData?.title?.en ?? ""}
											id="AchivmentNameEn"
											placeholder="ادخل اسم الانجاز بالانجليزية"
											onChange={e =>
												setAchivmentData({
													...AchivmentData,
													title: { ...AchivmentData.title, en: e.target.value },
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flexDirection: "column",
											}}
										>
											<input
												type="search"
												name="Count"
												id="otherInfo"
												value={AchivmentData?.value ?? 0}
												onChange={e =>
													e.target.value.length < 10 && Number(e.target.value)
														? setAchivmentData({
																...AchivmentData,
																value: e.target.value,
														  })
														: e.target.value === ""
														? ""
														: (e.target.value =
																"الرجاء ادخال البيانات بشكل صحيح")
												}
												placeholder="الرجاء ادخل معلومة رقمية عن الانجاز"
												style={{ width: "100%" }}
											/>
											<label htmlFor="otherInfo" style={{ fontSize: "12px" }}>
												{" "}
												<span style={{ color: "red" }}>*</span> المعلومة الرقمية
												عن الانجاز
											</label>
										</div>
									</Col>
								</Row>
							</Container>
						</Col>
						<Col md={5}>
							<div className="card">
								<div className="card-body">
									<div className="form-item">
										<input type="file" onChange={handlePicture} id="picture" />
										<img
											src={
												dataLoaded
													? `${process.env.REACT_APP_MAIN_URL}${picture}`
													: picture
											}
											alt="avatar"
											className="imagePreview"
										/>
									</div>
								</div>
							</div>
							<div className="form-item mt-3" onClick={HandleSubmit}>
								<button className="bt bt-success btn-block">ارسال</button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default AchivmentShow;
