import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDropzone } from "react-dropzone";
import { Col, Container, Row, FormGroup } from "reactstrap";

import { useHistory } from "react-router-dom";
// TODO END WORK AND THEN BANNERS AND CONNET SERVICES AND MAKE ALL RESPONSEV
const WorkShow = ({ match }) => {
	const {
		params: { workID },
	} = match;
	const auth = useSelector(state => state.auth.user);
	const [loading, setLoading] = useState(true);
	const [Group, setGroup] = useState(0);
	const [title, setTitle] = useState("");
	const [titleEn, setTitleEn] = useState("");
	const [DescriptionEn, setDescriptionEn] = useState("");
	const [DescriptionAr, setDescriptionAr] = useState("");
	// Info
	const [expireDate, setExpireDate] = useState("");
	const [budget, setBudget] = useState({});
	const [category, setCategory] = useState({});
	const [customerwebsite, setustomerwebsite] = useState("");
	const [client, setClient] = useState({});
	const [country, setCountry] = useState({});

	const [files, setFiles] = useState([]);
	const [vedio, setVedio] = useState();
	const [imageArray, setImageArray] = useState([]);
	const [picture, setPicture] = useState("");
	const dispatch = useDispatch();

	const videoRef = useRef(null);

	const history = useHistory();
	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	useEffect(() => {
		if (videoRef !== null && videoRef.current !== null) {
			videoRef.current.load();
		}
	}, [vedio]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					console.log("imges", responseImage);
					setImageArray([...imageArray, responseImage.data.data.name]);
				})
				.catch(function (error) {
					console.log(error);
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل العمل",
				breadcrumb: "الأعمال",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/${workID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log(response);
				setTitle(response?.data?.data?.data?.title?.ar ?? "");
				setTitleEn(response?.data?.data?.data?.title?.en ?? "");
				setDescriptionAr(response?.data?.data?.data?.description?.ar ?? "");
				setDescriptionEn(response?.data?.data?.data?.description?.en ?? "");
				setPicture(response?.data?.data?.data?.main_image ?? "");
				setExpireDate(response?.data?.data?.data?.expireDate ?? "");
				setBudget(response?.data?.data?.data?.budget ?? { ar: "", en: "" });
				setCategory(response?.data?.data?.data?.category ?? { ar: "", en: "" });
				setustomerwebsite(response?.data?.data?.data?.customer_website ?? "");
				setClient(response?.data?.data?.data?.customer ?? { ar: "", en: "" });
				setCountry(response?.data?.data?.data?.country ?? { ar: "", en: "" });
				setImageArray(response.data.data.data.images ?? []);
				setGroup(response?.data?.data?.data?.GroupType ?? 0);
				setVedio(
					response?.data?.data?.data?.video ??
						"/storage/uploads/ZC9TaJ-1636382296.mp4"
				);

				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const handlePicture = () => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		// setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleVideo = e => {
		setLoading(true);
		const fileInput = e.target;
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setVedio(response.data.data.name);
				console.log(response);

				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				console.log(error);
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const data = {
			title: {
				ar: title,
				en: titleEn,
			},
			// {,expireDate,budget ,category,client,customerwebsite,country,vedio}
			description: {
				ar: DescriptionAr,
				en: DescriptionEn,
			},
			GroupType: Group,
			expireDate: expireDate,
			budget: budget,
			category: category,
			customer: client,
			customer_website: customerwebsite,
			country: country,
			main_image: picture,
			video: vedio,
			images: imageArray,
			descriptionInBrief: {
				ar: new DOMParser().parseFromString(DescriptionAr, "text/html").body
					.innerText,
				en: new DOMParser().parseFromString(DescriptionEn, "text/html").body
					.innerText,
			},
		};

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/${workID}`,
				{
					category_id: 31,
					admin_id: auth.id,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/works/index");

				toast.success("تم تحديث بيانات العمل بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-9">
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان</label>
											<input
												type="text"
												id="title"
												required
												value={title}
												onChange={e => setTitle(e.target.value)}
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">العنوان بالانجليزيه</label>
											<input
												type="text"
												id="title"
												required
												value={titleEn}
												onChange={e => setTitleEn(e.target.value)}
											/>
										</div>
									</div>

									{/* Description Section */}
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="title">الوصف</label>
											<CKEditor
												data={DescriptionAr ?? ""}
												onReady={editor => {
													editor.ui
														.getEditableElement()
														.parentElement.insertBefore(
															editor.ui.view.toolbar.element,
															editor.ui.getEditableElement()
														);
													editor = editor;
												}}
												onError={({ willEditorRestart }) => {
													if (willEditorRestart) {
														editor.ui.view.toolbar.element.remove();
													}
												}}
												onChange={(event, editor) => {
													const data = editor.getData();
													setDescriptionAr(data);
												}}
												editor={DecoupledEditor}
												config={{
													contentsLangDirection: "rtl",
													language: "ar",
													extraPlugins: [MyCustomUploadAdapterPlugin],
												}}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-item">
											<label htmlFor="DescriptionEN">الوصف بالانجليزية</label>
											<CKEditor
												data={DescriptionEn ?? ""}
												onReady={editor => {
													editor.ui
														.getEditableElement()
														.parentElement.insertBefore(
															editor.ui.view.toolbar.element,
															editor.ui.getEditableElement()
														);
													editor = editor;
												}}
												onError={({ willEditorRestart }) => {
													if (willEditorRestart) {
														editor.ui.view.toolbar.element.remove();
													}
												}}
												onChange={(event, editor) => {
													const data = editor.getData();
													setDescriptionEn(data);
												}}
												editor={DecoupledEditor}
												config={{
													contentsLangDirection: "ltr",
													language: "en",
													extraPlugins: [MyCustomUploadAdapterPlugin],
												}}
											/>
										</div>
									</div>
									{/* Description ends Here */}

									{/* ProjectInfo Section */}
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="ClientAr">اسم العميل </label>
												<input
													type="text"
													id="ClientAr"
													required
													value={client?.ar ?? ""}
													onChange={e =>
														setClient({ ...client, ar: e.target.value })
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="ClientEn">اسم العميل بالانجليزية</label>
												<input
													type="text"
													id="ClientEn"
													required
													value={client?.en ?? ""}
													onChange={e =>
														setClient({ ...client, en: e.target.value })
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="CountryAr">اسم البلد </label>
												<input
													type="text"
													id="CountryAr"
													required
													value={country?.ar ?? ""}
													onChange={e =>
														setCountry({ ...country, ar: e.target.value })
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="CountryEn">اسم البلد بالانجليزية</label>
												<input
													type="text"
													id="CountryEn"
													required
													value={country?.en ?? ""}
													onChange={e =>
														setCountry({ ...country, en: e.target.value })
													}
												/>
											</div>
										</div>
									</FormGroup>
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="categoryAr">اسم القسم </label>
												<input
													type="text"
													id="categoryAr"
													required
													value={category?.ar ?? ""}
													onChange={e =>
														setCategory({ ...category, ar: e.target.value })
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="categoryEn">
													اسم القسم بالانجليزية
												</label>
												<input
													type="text"
													id="categoryEn"
													required
													value={category?.en ?? ""}
													onChange={e =>
														setCategory({ ...category, en: e.target.value })
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="ExpirdataAr">تاريخ الانتهاء</label>
												<input
													type="date"
													id="ExpirdataAr"
													required
													onClick={() => console.log(expireDate)}
													value={expireDate ?? ""}
													onKeyPress={e => setExpireDate(e.target.value)}
													onChange={e => setExpireDate(e.target.value)}
												/>
											</div>
										</div>
									</FormGroup>
									<FormGroup style={{ display: "flex" }}>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="budgetAr">الميزانية</label>
												<input
													type="text"
													id="budgetAr"
													required
													value={budget?.ar ?? ""}
													onChange={e =>
														setBudget({ ...budget, ar: e.target.value })
													}
												/>
											</div>
											<div className="form-item">
												<label htmlFor="budgetEn"> الميزانية بالانجليزية</label>
												<input
													type="text"
													id="budgetEn"
													required
													value={budget?.en ?? ""}
													onChange={e =>
														setBudget({ ...budget, en: e.target.value })
													}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-item">
												<label htmlFor="customerwebsiteAr">اسم موقع </label>
												<input
													type="text"
													id="customerwebsiteAr"
													required
													value={customerwebsite ?? ""}
													onChange={e => setustomerwebsite(e.target.value)}
												/>
											</div>
										</div>
									</FormGroup>
									{/* ProjectInfo ends Here */}
									{/* Images Zone*/}

									<div className="dropzoneContainer">
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<p>اضغط هنا لرفع الصورة الداخلية</p>
										</div>
										<aside className="thumbsContainer">{thumbs}</aside>
										<button
											onClick={handleImageUpload}
											type="button"
											className="bt bt-success"
										>
											رفع الصورة
										</button>
									</div>

									<div className="row">
										{imageArray.map(img => (
											<div className="col-md-2" key={img}>
												<div className="image-preview">
													<img
														src={`${process.env.REACT_APP_MAIN_URL}${img}`}
														alt={img}
														className="imgList"
													/>
													<button onClick={e => deleteImage(e, img)}>
														<i className="fas fa-trash"></i>
													</button>
												</div>
											</div>
										))}
									</div>
									{/* Images Zone Ends Here */}
									{/* Video Zone  */}
									<div className="row">
										<Col xs={12}>
											<label htmlFor="Video" style={{ position: "relative" }}>
												الفديو
												<span
													style={{
														position: "absolute",
														top: "50%",
														left: "-50%",
														fontSize: "20px",
														cursor: "pointer",
														fontWeight: "bolder",
														transform: "translateY(-50%)",
													}}
												>
													<i>+</i>
												</span>
											</label>
											<input
												type="file"
												id="Video"
												hidden
												accept="video/mp4,video/x-m4v,video/*"
												onChange={handleVideo}
											/>
											<div className="" style={{ position: "relative" }}>
												<video
													controls
													ref={videoRef}
													style={{ width: "100%" }}
												>
													<source
														src={
															vedio
																? process.env.REACT_APP_MAIN_URL + vedio
																: process.env.REACT_APP_MAIN_URL +
																  "/storage/uploads/ZC9TaJ-1636382296.mp4"
														}
														type="video/mp4"
													></source>
												</video>
												{/* <i
													style={{
														position: "absolute",
														color: "white",
														border: "1px solid black",
														borderRadius: "1000px",
														padding: "1rem",
														fontSize: "20px",
														top: "50%",
														left: "50%",
														transform: "translate(-50%, -50%)",
													}}
													onClick
													className="fas fa-play"
												></i> */}
											</div>
										</Col>
									</div>
									{/* Video Zone Ends Here */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<input type="file" onChange={handlePicture} id="picture" />
									<img
										src={process.env.REACT_APP_MAIN_URL + picture}
										alt="avatar"
										className="imagePreview"
									/>
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<div className="card">
								<div className="card-body">
									<label htmlFor="GroupSelector">النوع</label>
									<select
										name=""
										id="GroupSelector"
										onChange={e => setGroup(e.target.value)}
										value={Group}
									>
										<option value="2">المقاولات</option>
										<option value="1">هيئات حكومية</option>
										<option value="0">الاستشارات الهندسية</option>
									</select>
								</div>
							</div>
						</div>

						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get("FileRepository").createUploadAdapter = loader => {
		return new MyUploadAdapter(loader);
	};
}

class MyUploadAdapter {
	constructor(props) {
		this.loader = props;
		this.url = `${process.env.REACT_APP_API_URL}/upload`;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = (this.xhr = new XMLHttpRequest());

		xhr.open("POST", this.url, true);
		xhr.responseType = "json";
		xhr.setRequestHeader("Authorization", `Bearer ${cookie.get("token")}`);
	}

	_initListeners(resolve, reject) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;

		xhr.addEventListener("error", () => reject(genericErrorText));
		xhr.addEventListener("abort", () => reject());
		xhr.addEventListener("load", () => {
			const response = xhr.response;
			if (!response || response.error) {
				return reject(
					response && response.error ? response.error.message : genericErrorText
				);
			}
			resolve({
				default: response.data.filePath,
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener("progress", evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	_sendRequest() {
		const data = new FormData();
		this.loader.file.then(result => {
			data.append("file", result);
			this.xhr.send(data);
		});
	}
}

export default WorkShow;
