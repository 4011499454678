let id = 1;
const randomId = () => {
	id++;
	return id;
};

export const navbarItems = [
	{
		id: randomId(),
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: randomId(),
		link: "/moderators/index",
		icon: "fas fa-user",
		title: "المشرفين",
		children: [
			{
				id: randomId(),
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: randomId(),
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/blog/index",
		icon: "fas fa-edit",
		title: "المدونة",
		children: [
			{
				id: randomId(),
				title: "جميع المقالات",
				link: "/blog/index",
			},
			{
				id: randomId(),
				title: "اضافة مقال جديد",
				link: "/blog/create",
			},
			{
				id: randomId(),
				title: "التصنيفات",
				link: "/categories/index",
			},
			{
				id: randomId(),
				title: "الكلمات الدلالية",
				link: "/tag/index",
			},
		],
	},
	{
		id: randomId(),
		link: "/services/index",
		icon: "fas fa-layer-group",
		title: "الخدمات",
		children: [
			{
				id: randomId(),
				title: "جميع الخدمات",
				link: "/services/index",
			},
			{
				id: randomId(),
				title: "اضافة خدمة جديدة",
				link: "/services/create",
			},
			{
				id: randomId(),
				title: "طلبات الخدمات",
				link: "/services/applications",
			},
		],
	},
	{
		id: randomId(),
		link: "/achivment/index",
		icon: "fas fa-award",
		title: "الانجازات",
		children: [
			{
				id: randomId(),
				title: "جميع الانجازات",
				link: "/achivment/index",
			},
			{
				id: randomId(),
				title: "اضافة انجاز جديدة",
				link: "/achivment/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/comments/index",
		icon: "fas fa-comments",
		title: "اراء العملاء",
		children: [
			{
				id: randomId(),
				title: "جميع الاراء",
				link: "/comments/index",
			},
			{
				id: randomId(),
				title: "اضافة رائ جديدة",
				link: "/comments/create",
			},
		],
	},
	

	// {
	// 	id: randomId(),
	// 	link: "/partners/index",
	// 	icon: "fas fa-handshake",
	// 	title: "الشركاء",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع الشركاء",
	// 			link: "/partners/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة شريك جديدة",
	// 			link: "/partners/create",
	// 		},
	// 	],
	// },

	{
		id: randomId(),
		link: "/contact/index",
		icon: "fas fa-envelope",
		title: "اتصل بنا",
		children: [],
	},
	

	// {
	// 	id: randomId(),
	// 	link: "/mainVideo/index",
	// 	icon: "fas fa-envelope",
	// 	title: "الفيديو",
	// 	children: [],
	// },

	// {
	// 	id: randomId(),
	// 	link: "/footerLinks/index",
	// 	icon: "fas fa-link",
	// 	title: "روابط الفوتر",
	// 	children: [],
	// },

	// {
	// 	id: randomId(),
	// 	link: "/spliters/index",
	// 	icon: "fas fa-link",
	// 	title: " الفواصل",
	// 	children: [],
	// },

	// {
	// 	id: randomId(),
	// 	link: "/benefits/benefitsHeader/index",
	// 	icon: "fas fa-id-card",
	// 	title: "قسم الفوائد",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: " العنوان",
	// 			link: "/benefits/benefitsHeader/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفيديوهات",
	// 			link: "/benefits/benefitsVideo/index",
	// 		},
	// 	],
	// },

	{
		id: randomId(),
		link: "/slider/index",
		icon: "far fa-images",
		title: "سلايدر",
		children: [
			{
				id: randomId(),
				title: "جميع السلايدات",
				link: "/slider/index",
			},
			{
				id: randomId(),
				title: "اضافة سلايد جديد",
				link: "/slider/create",
			},
		],
	},

	// {
	// 	id: randomId(),
	// 	link: "/gallery/index",
	// 	icon: "far fa-images",
	// 	title: "المعرض",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع صور المعرض",
	// 			link: "/gallery/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة صوره جديد",
	// 			link: "/gallery/create",
	// 		},
	// 	],
	// },

	// {
	// 	id: randomId(),
	// 	link: "/banners/index",
	// 	icon: "far fa-images",
	// 	title: "البانر",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "جميع صور البانر",
	// 			link: "/banners/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "اضافة صوره جديد",
	// 			link: "/banners/create",
	// 		},
	// 	],
	// },

	{
		id: randomId(),
		link: "/works/index",
		icon: "fas fa-images",
		title: "صفحه أعمالنا ",
		children: [
			{
				id: randomId(),
				title: "جميع الأعمال",
				link: "/works/index",
			},
			{
				id: randomId(),
				title: "اضافة عمل جديد",
				link: "/works/create",
			},
			{
				id: randomId(),
				title: "جميع الفيديوهات ",
				link: "/works/worksvideos/index",
			},
		],
	},

	// {
	// 	id: randomId(),
	// 	link: "/centers/index",
	// 	icon: "fas fa-car",
	// 	title: "صفحه المراكز المعتمدة ",
	// 	children: [],
	// },

	{
		id: randomId(),
		link: "/aboutPage",
		icon: "far fa-images",
		title: " بيانات الموقع ",
		children: [
			{
				id: randomId(),
				title: "البيانات لاساسيه",
				link: "/aboutPage/header/index",
			},
			
			{
				id: randomId(),
				title: "البيانات الاضافيه",
				link: "/aboutPage/sideinfo/index",
				
			},
			// {
			// 	id: randomId(),
			// 	title: "المميزات",
			// 	link: "/aboutPage/advantages/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الاحصائيات",
			// 	link: "/aboutPage/statistics/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الأسئله الشائعه",
			// 	link: "/aboutPage/questions/index",
			// },
		],
	},
	
	// {
	// 	id: randomId(),
	// 	link: "/automotivePage",
	// 	icon: "far fa-images",
	// 	title: "صفحه المنتجات",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/automotivePage/automotivePosts/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفيديو",
	// 			link: "/automotivePage/automotiveVideo/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "السكاشن",
	// 			link: "/automotivePage/automotiveSections/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "العناوين",
	// 			link: "/automotivePage/automotiveHeaders/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفواصل",
	// 			link: "/automotivePage/automotiveSpliters/index",
	// 		},
	// 	],
	// },
	
	// {
	// 	id: randomId(),
	// 	link: "/productOnePage",
	// 	icon: "far fa-images",
	// 	title: " صفحه المنتج الاول",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/productOnePage/ProductOneHeader/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "البوستات",
	// 			link: "/productOnePage/ProductOnePosts/index",
	// 		},
	// 	],
	// },
	
	
	// {
	// 	id: randomId(),
	// 	link: "/productTwoPage",
	// 	icon: "far fa-images",
	// 	title: " صفحه المنتج الثاني",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/productTwoPage/ProductTwoHeader/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "البوستات",
	// 			link: "/productTwoPage/ProductTwoPosts/index",
	// 		},
	// 	],
	// },


	// // {
	// 	id: randomId(),
	// 	link: "/productThreePage",
	// 	icon: "far fa-images",
	// 	title: " صفحه المنتج الثالث",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/productThreePage/ProductThreeHeader/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "البوستات",
	// 			link: "/productThreePage/ProductThreePosts/index",
	// 		},
	// 	],
	// },


	// {
	// 	id: randomId(),
	// 	link: "/industryPage",
	// 	icon: "far fa-images",
	// 	title: "صفحه الصناعه",
	// 	children: [
	// 		{
	// 			id: randomId(),
	// 			title: "البيانات لاساسيه",
	// 			link: "/industryPage/posts/index",
	// 		},
	// 		{
	// 			id: randomId(),
	// 			title: "الفيديو",
	// 			link: "/industryPage/video/index",
	// 		},
	// 	],
	// },

	// {
	// 	id: randomId(),
	// 	link: "/newsletter/index",
	// 	icon: "far fa-envelope",
	// 	title: "القائمة البريدية",
	// 	children: [],
	// },
];
