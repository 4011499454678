import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";

const AchivmentCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [picture, setPicture] = useState(userPlaceholder);
	const [AchivmentData, setAchivmentData] = useState({});
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة الانجازات جديد",
				breadcrumb: "الانجازات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	useEffect(() => {
		onStart();
	});

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};
	const HandleSubmit = e => {
		e.preventDefault();
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					if (
						!AchivmentData.title.ar ||
						!AchivmentData.title.en ||
						!AchivmentData.value ||
						!response.data.data.name
					)
						return;
					const data = {
						// data Form
						title: AchivmentData.title,
						value: AchivmentData.value,
						image: response.data.data.name,
					};

					axios
						.post(
							`${process.env.REACT_APP_API_URL}/posts`,
							{
								category_id: 46,
								admin_id: auth.id,
								data: data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/achivment/index");
							toast.success("تم اضافة الانجاز بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<div style={{ width: "100%" }}>
				<Container>
					<Row>
						<Col>
							<Container
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									flexDirection: "column",
									gap: "2rem",
								}}
							>
								<Row style={{ width: "100%" }}>
									<Col>
										<input
											type="text"
											name=""
											id="AchivmentNameAR"
											placeholder="ادخل اسم الانجاز"
											onChange={e =>
												setAchivmentData({
													...AchivmentData,
													title: { ...AchivmentData.title, ar: e.target.value },
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<input
											type="text"
											name=""
											id="AchivmentNameEn"
											placeholder="ادخل اسم الانجاز بالانجليزية"
											onChange={e =>
												setAchivmentData({
													...AchivmentData,
													title: { ...AchivmentData.title, en: e.target.value },
												})
											}
										/>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flexDirection: "column",
											}}
										>
											<input
												type="search"
												name="Count"
												id="otherInfo"
												onChange={e =>
													e.target.value.length < 10 && Number(e.target.value)
														? setAchivmentData({
																...AchivmentData,
																value: e.target.value,
														  })
														: e.target.value === ""
														? ""
														: (e.target.value =
																"الرجاء ادخال البيانات بشكل صحيح")
												}
												placeholder="الرجاء ادخل معلومة رقمية عن الانجاز"
												style={{ width: "100%" }}
											/>
											<label htmlFor="otherInfo" style={{ fontSize: "12px" }}>
												{" "}
												<span style={{ color: "red" }}>*</span> المعلومة الرقمية
												عن الانجاز
											</label>
										</div>
									</Col>
								</Row>
							</Container>
						</Col>
						<Col md={5}>
							<div className="card">
								<div className="card-body">
									<div className="form-item">
										<input type="file" onChange={handlePicture} id="picture" />
										<img src={picture} alt="avatar" className="imagePreview" />
									</div>
								</div>
							</div>
							<div className="form-item mt-3" onClick={HandleSubmit}>
								<button className="bt bt-success btn-block">ارسال</button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default AchivmentCreate;
