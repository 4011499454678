import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import Loader from "../../components/shared/Loader";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { updateMeta } from "../../store/actions/metaAction";
import { v4 } from "uuid";

const BlogCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const parser = new DOMParser();
	const history = useHistory();
	const [title, setTitle] = useState({});
	const [description, setDescription] = useState({});
	const [qouts, setqouts] = useState({});
	const [contant, setContant] = useState({});
	const [picture, setPicture] = useState(userPlaceholder);
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const dispatch = useDispatch();
	const [tagsOptions, setTagsOptions] = useState([]);
	const [selectTages, setSelectTags] = useState([]);

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة مقال جديد",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/blog_categories`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data);
				axios
					.get(`${process.env.REACT_APP_API_URL}/tags`, {
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					})
					.then(response => {
						setTagsOptions([
							...response.data.data.map(option => ({
								value: option.id,
								label: option.name,
							})),
						]);
						setLoading(false);
					})
					.catch(function (error) {
						toast.error("خطأ، برجاء المحاولة لاحقا!");
					});
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleTags = options => {
		setSelectTags([...options.map(option => option.value)]);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (selectedCategory == 0) {
			toast.error("برجاء اختيار التصنيف أولا!");
			return;
		}
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/blogs`,
							{
								title: title,
								description: description,
								contant: contant,
								quots: qouts,
								descriptionInBr: {
									ar:
										parser.parseFromString(description.ar, "text/html").body
											.children[0].innerText + "...",
									en:
										parser.parseFromString(description.en, "text/html").body
											.children[0].innerText + "...",
								},
								tags: selectTages,
								admin_id: auth.id,
								category_id: selectedCategory,
								image: response.data.data.name,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/blog/index");
							toast.success("تم اضافة المقالة بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			console.log("Test2");
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-9">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="category">التصنيف</label>
								<select
									id="category"
									onChange={e => setSelectedCategory(e.target.value)}
								>
									<option value="0">اختر التصنيف</option>
									{categories.map(category => (
										<option value={category.id} key={category.id}>
											{category.name.ar}
										</option>
									))}
								</select>
							</div>
							<div className="form-item">
								<label htmlFor="titleAR">العنوان</label>
								<input
									type="text"
									id="titleAR"
									required
									onChange={e =>
										setTitle(v => {
											return { ...v, ar: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="titleEn">العنوان باللغة الإنجليزية</label>
								<input
									type="text"
									id="titleEn"
									required
									onChange={e =>
										setTitle(v => {
											return { ...v, en: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="qouteAR">اقتبس</label>
								<input
									type="text"
									id="qouteAR"
									required
									onChange={e =>
										setqouts(v => {
											return { ...v, ar: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="qouteEn">اقتبس باللغة الإنجليزية</label>
								<input
									type="text"
									id="qouteEn"
									required
									onChange={e =>
										setqouts(v => {
											return { ...v, en: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="descriptionAr">الوصف</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescription(v => {
											return { ...v, ar: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="descriptionEn">الوصف باللغة الإنجليزية</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescription(v => {
											return { ...v, en: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="contantAr">المحتوي</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContant(v => {
											return { ...v, ar: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="contantEn">المحتواي بالغة الانجليزية</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContant(v => {
											return { ...v, en: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>
							<div className="form-item">
								<div className="" style={{ marginBottom: "8rem" }}>
									<Select
										isSearchable={true}
										isLoading={loading}
										isMulti={true}
										onChange={handleTags}
										options={tagsOptions}
									></Select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<input type="file" onChange={handlePicture} id="picture" />
								<img src={picture} alt="avatar" className="imagePreview" />
							</div>
						</div>
					</div>
					<div className="form-item mt-3">
						<button className="bt bt-success btn-block" onClick={handleSubmit}>
							ارسال
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogCreate;
